

























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Product from '@/models/graphql/Product';
import LargeProduct from '@/models/graphql/LargeProduct';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';

@Component({
  components: { ButtonComponent },
})
export default class FinancingCalculatorWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop()
  private data!: Array<Product | LargeProduct>;

  @Prop()
  private type!: string;

  private form: { price: string; years: string; interestRate: string; downPayment: string } = {
    price: '',
    years: '',
    interestRate: '',
    downPayment: '',
  };

  private result = 0;

  private interestRateMask = createNumberMask({
    prefix: '',
    suffix: '%',
    integerLimit: 3,
    decimalLimit: 5,
    allowDecimal: true,
    includeThousandsSeparator: false,
    placeholder: '0.00',
  });

  private yearsMask = createNumberMask({
    prefix: '',
    suffix: '',
    placeholder: '0',
  });

  private priceMask = createNumberMask({
    prefix: this.priceCurrency(),
    suffix: '',
    placeholder: '0.00',
    allowDecimal: true,
    decimalLimit: 2,
  });

  private get entity(): Product | LargeProduct | null {
    if (this.data && this.data.length > 0) {
      return this.data[0];
    }
    return null;
  }

  @Watch('entity', { immediate: true })
  setPrice(): void {
    this.form.price = this.entity && this.entity.price ? this.entity.price.toString() : '';
  }

  created(): void {
    if (this.payload
      && 'entityCode' in this.payload
      && this.payload.entityCode
      && 'type' in this.payload
      && this.payload.type) {
      let filterType = '';
      let operation = '';
      let fragmentName = '';
      switch (this.payload.type as string) {
        case 'product':
          filterType = GqlEntityFilterType.PRODUCT_FILTER;
          operation = 'product';
          fragmentName = 'productBaseFragment';
          break;
        case 'largeProduct':
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          operation = 'largeProduct';
          fragmentName = 'largeProductBaseFragment';
          break;
        default:
          break;
      }
      this.setDataConfig([{
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: filterType,
            value: { uid: this.payload.entityCode },
          },
        }),
        operation,
        fragmentName,
        alias: this.storeName,
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
      );
    }
  }

  onCalculateClick(): void {
    const price = !this.form.price ? 0 : parseFloat(this.form.price
      .replace('$', '').replaceAll(',', ''));
    const downPayment = !this.form.downPayment ? 0 : parseFloat(this.form.downPayment
      .replace('$', '').replaceAll(',', ''));
    const interestRate = !this.form.interestRate ? 0 : parseFloat(this.form.interestRate
      .replace('%', ''));
    const years = !this.form.years ? 0 : parseInt(this.form.years, 10);
    this.result = price;
    if (price > 0 && price > downPayment) {
      this.result = price - downPayment;
      if (interestRate > 0) {
        this.result += (this.result * interestRate) / 100;
      }
      if (years > 0) {
        this.result /= years * 12;
      }
      this.result = parseFloat(this.result.toFixed(2));
    }
    this.$logger.log(['trackEvent', StatLoggerCategories.FINANCING_CALCULATOR, StatLoggerActions.CLICK, null, null, {
      ...this.$logger.serialiseAuthUserDimensions(null, this.community.code ?? '', this.type),
      dimension2: this.$i18n.locale,
      dimension8: this.entity ? this.entity.uid : '',
    }]);
  }

  private priceCurrency(): string {
    return `${this.$t('app.currency')}`;
  }
}
